import "./App.css";
import HomePage from "./page/HomePage";

function App() {
  return (
    <div className="w-full p-4">
      <HomePage/>
    </div>
  );
}

export default App;
